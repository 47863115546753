<template functional>
  <div class="md:tw-grid md:tw-grid-cols-2 tw-gap-x-6">
    <div class="tw-flex-grow">
      <FormulateInput
        type="text"
        name="reference"
        label="Referentie"
        placeholder="Referentie"
      />
      <FormulateInput
        type="multiselect"
        name="type"
        label="Type"
        placeholder="Type"
        :options="$options.PROPERTY_TYPES"
      />
      <div class="tw-my-4 tw-grid md:tw-grid-cols-3 tw-gap-4">
        <FormulateInput
          type="multiselect"
          name="status"
          label="Status"
          placeholder="Status"
          :options="$options.PROPERTY_STATUSES"
          outer-class="tw-m-0"
        />
        <FormulateInput
          type="multiselect"
          name="transaction_type"
          label="Transactietype"
          placeholder="Transactietype"
          :options="$options.PROPERTY_TRANSACTION_TYPES"
          outer-class="tw-m-0"
        />
        <FormulateInput
          type="multiselect"
          name="epc_labels"
          label="EPC-label"
          placeholder="EPC-label"
          :options="$options.EPC_LABELS"
          outer-class="tw-m-0"
        />
      </div>
      <fieldset class="fieldset-split-range">
        <legend>Prijs</legend>
        <FormulateInput
          type="number"
          name="price_min"
          min="0"
          lang="nl"
          placeholder="Min"
          outer-class="tw-m-0"
        />
        <FormulateInput
          type="number"
          name="price_max"
          min="0"
          lang="nl"
          placeholder="Max"
          outer-class="tw-m-0"
        />
      </fieldset>
      <div class="tw-my-4 tw-flex tw-gap-4 tw-flex-wrap">
        <FormulateInput
          type="checkbox"
          name="is_published"
          label="Enkel gepubliceerde panden"
          outer-class="tw-m-0"
        />
        <FormulateInput
          type="checkbox"
          name="include_archived"
          label="Gearchiveerde panden"
          outer-class="tw-m-0"
        />
        <FormulateInput
          type="checkbox"
          name="is_newly_built"
          label="Nieuwbouw"
          outer-class="tw-m-0"
        />
        <FormulateInput
          type="checkbox"
          name="is_luxury"
          label="Luxevastgoed"
          outer-class="tw-m-0"
        />
        <FormulateInput
          type="checkbox"
          name="has_development_potential"
          label="Ontwikkelingspotentieel"
          outer-class="tw-m-0"
        />
      </div>
    </div>

    <div>
      <FormulateInput
        type="text"
        name="street"
        label="Straat"
        placeholder="Straat"
      />
      <div class="tw-my-4 tw-grid tw-grid-cols-2 tw-gap-4">
        <FormulateInput
          type="text"
          name="number"
          label="Nr"
          placeholder="Nr"
          outer-class="tw-m-0"
        />
        <FormulateInput
          type="text"
          name="box"
          label="Bus"
          placeholder="Bus"
          outer-class="tw-m-0"
        />
      </div>
      <FormulateInput
        type="autocomplete"
        auto-complete-type="city"
        :multiple="true"
        name="cities"
        label="Plaats"
        placeholder="Zoek op postcode, plaatsnaam of provincie"
        @input="listeners['citySelected']($event)"
      />
      <fieldset class="fieldset-split-range">
        <legend>Slaapkamers</legend>
        <FormulateInput
          type="number"
          name="bedroom_count_min"
          min="0"
          placeholder="Min"
          outer-class="tw-m-0"
        />
        <FormulateInput
          type="number"
          name="bedroom_count_max"
          min="0"
          placeholder="Max"
          outer-class="tw-m-0"
        />
      </fieldset>

    </div>
  </div>
</template>

<script>
import { NICHES, EPC_LABELS, PROPERTY_TYPES, PROPERTY_STATUSES, PROPERTY_TRANSACTION_TYPES } from '@/forms/selectOptions'

export default {
  name: 'PropertySearchGeneral',
  NICHES,
  EPC_LABELS,
  PROPERTY_TYPES,
  PROPERTY_STATUSES,
  PROPERTY_TRANSACTION_TYPES
}
</script>
